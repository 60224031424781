// ibif
// import $ from 'jquery';
import SwiperImplementation from './lib/_swiper.js';

$(document).ready(() => {
    $('.ibif-seotext .ib-showall').on('click', function() {
        $(this).hide();
        $('.ibif-seotext .ib-hidden').fadeIn();
    });

    const swiper = new SwiperImplementation();
    const indexSlidesSwiper = () => {
        console.log('aaa');
        swiper.init('.ibifSliderSwipe', {
          keyboard: {
            enabled: true,
            onlyInViewport: false,
          },
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },        
        });
        console.log('bbb');
      }
      indexSlidesSwiper();  
});